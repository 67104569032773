.each-proj {
  cursor: auto;
  width: 95%;
  margin: 0px 5px;
  padding: 20px;
  border-radius: 20px;
  transition: all 0.3s ease-out;
  box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.174);
  background-color: var(--body-color);
  .each-slide {
    display: flex;
    gap: 20px;

    .left {
      flex: 1;
      margin: 0px auto;

      .thumb-img {
        height: 100%;
        width: 100%;
        padding: 0px 4px;

        border-radius: 10px;
        img {
          border-radius: 10px;
          width: 100%;
          border: 1px solid var(--faintest-col);
          object-fit: contain;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      gap: 30px;
      padding: 0px 5px;
      flex: 1;

      .heading {
        .head {
          font-size: var(--h4-font-size);
          color: var(--allh-col);
        }
      }

      .mid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        .desc {
          font-family: var(--para2-font);
        }
        .stack-used {
          display: flex;
          flex-direction: column;
          gap: 10px;
          p {
            color: var(--h1-col);
          }
          .logos {
            gap: 10px 20px;
            display: flex;
            flex-wrap: wrap;
          }
        }
      }

      .buttons {
        display: flex;
        gap: 10px;
      }
    }
  }

  &:hover {
    box-shadow: 1px 1px 15px 2px rgba(0, 0, 0, 0.193);
  }
}

@media screen and (max-width: 768px) {
  .each-proj {
    .each-slide {
      padding: 0px;
      flex-direction: column;
      gap: 15px;

      .left {
        padding: 10px;
        .thumb-img {
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .right {
        gap: 15px;
        .mid {
          .desc {
            font-size: var(--media-p-font-size);
          }
        }

        .buttons {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .each-proj {
    padding: 20px;
    .each-slide {
      // min-height: 435px;
      gap: 20px;
      .right {
        .mid {
          .desc {
            text-align: justify;
          }
        }
      }

      .left {
        flex: 1;
        padding: 0px;
        .thumb-img {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
