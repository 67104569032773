.navbar {
  position: fixed;
  height: var(--header-height);
  color:white;
  background-color: var(--footer-col);
  box-shadow: 0px 0px 8px var(--prim-active-col);
  top: 0;
  z-index: 99;
  width: 100%;
  .content {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    
    .left {
      h2 {
        a{
          color: white;
          font-size: var(--big-font-size);

        }
        display: inline;
      }
    }
    .right {
      display: flex;
      font-family: var(--para-font);
      height: 100%;
      align-items: center;
      gap: 12px;

      .uil-apps{
        display: none;
      }
      

      ul {
        display: flex;
        gap: 10px;
        overflow: none;
        margin: 0px 4px;
        

        li {
          display: inline;
          overflow: hidden;

          a {
           
              color:white;
    
           
            font-size: var(--normal-font-size);
            // font-weight: 600;
            padding: 7px 15px;
            transition: all 0.1s ease-out;

            &:hover {
              color: var(--faintest-col);
            }
          }
          .active{

            color: var(--nav-active-col);
          }
        }
      }
      
      
      i:hover{
        color: var(--faintest-col);

      }
    }
  }
}








@media screen and (max-width: 1230px) {
  .navbar {
    // display: none;
    position: fixed;
    top: 0;
    .content {
      padding: 0px 10px;
      
    }
  }
}



@media screen and (max-width: 768px) {
  .navbar {
    .content {
      
      .left {
        h2 {
        a{
          font-size: var(--media-h1-font-size);
        }}
      }
      .right {
        ul {
          li{
            a{
              padding: 5px;
              font-size: var(--media-p-font-size);
            }
          }
        }
      }
    }
  }
}











@media screen and (max-width: 530px) {
  .navbar {
    // display: none;
    position: fixed;
    top: 0;
    .content {
      padding: 0px 10px;
      .right {
        ul {
          display: none;
          
        }
        .uil-apps{
          display: block;
        }
        .uil{
          
          font-size: var(--btn-font-size);
        }
        
      }
    }
  }
}
