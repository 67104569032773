.skill-overlay {
  flex: 1;
  min-width: 450px;
  padding: 10px 5px 30px 5px;
  border-right: 1px solid var(--fainter-col);

  .each-skill {
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: left;

    .head {
      display: grid;
      grid-template-columns: 0.6fr 3fr 1fr;
      gap: 5px 0px;
      h4 {
        color: var(--black-col);
      }
      .skill-icons {
        grid-column: 1/2;
        grid-row: 1/3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--prim-btn-col);

        font-size: var(--h1-font-size);
      }

      .desc {
        grid-row: 2;
        grid-column: 2/3;
      }

      .angle {
        display: flex;
        color: var(--prim-btn-col);
        margin-left: auto;
        grid-column: 3;
        grid-row: 1/3;
      }
    }

    .report {
      width: 70%;
      margin: 0px auto;

      display: flex;
      flex-direction: column;
      gap: 30px;

      transition: height 0.3s ease-in;
    }
  }
}

@media screen and (max-width: 768px) {
  .skill-overlay {
    min-width: 300px;
    
  border-right:none;
    
  border-bottom: 1px solid var(--fainter-col);
  }
}
