.all-sections {
  margin-top: var(--header-height);
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
}

::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width: 1230px) {
  .all-sections {
   
    gap: 40px;
    padding: 0px 15px;
  }
}

@media screen and (max-width: 470px) {
  .all-sections {
    margin-top: var(--header-height);
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 0px 10px;
  }
}

