.subnav {
  z-index: 98;
  background-color: var(--white-col);
  padding: 10px;

  border-radius: 20px;
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  max-width: 100%;
  margin: 5px;
  box-shadow: 0px 0px 5px 0px var(--prim-lighter-col);

  transition: all 0.2s ease-out;

  .content {
    ul {
      font-family: var(--para-font);
      display: grid;

      grid-template-columns: repeat(3, 1fr);


      gap: 20px 10px;
      li {
        height: fit-content;
        a {
          color: var(--black-col);
          i {
            font-size: var(--uil-font-size);
          }
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 5px 5px;

          text-align: center;
          font-size: var(--media-p-font-size);

          transition: all 0.1s ease-out;

          &:hover {
            color: var(--prim-btn-col);
          }
        }
        .active{

          color: var(--prim-active-col);
        }

      }
    }
  }
}
