.totop{
    position: fixed;
    bottom: 30px;
    right:10px;
    .arrow-div{
        background-color: var(--prim-btn-col);
        box-shadow: 0px 0px 2px .2px var(--black-col);
        margin: 8px;
        padding: 8px;
        color: white;
        border-radius: 5px;
        

        i{
            
            font-size: var(--p-font-size);
        }

    }
}