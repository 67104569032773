.tech{
    .content{
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        
        gap:8px;
        .logo{
            img{
                width: 25px;
            }
        }

        .title{
            font-family: var(--allh-font);
            font-size: var(--media-btn-font-size);
            color: var(--black-col);
        }
    }
}


@media screen and (max-width:480px){
    .tech{
        .content{     
            
        gap:5px;
            .logo{
            img{
               width:20px;



            }}}
}
}